<template>
  <div class="page-question-detail">
    <question :currentQuestion="currentQuestion" :explainVisible.sync="explainVisible">

    </question>
  </div>
</template>
<script>
import question from '../exam/components/question'
import { questionDetail } from '@/api/exam'
export default {
  components: {
    question
  },
  data() {
    return {
      explainVisible: false,
      currentQuestion: {}
    }
  },
  created() {
    this.question_id = this.$route.params.id;
    this.getData()
  },
  methods: {
    async getData() {
      const { data } = await questionDetail({
        id: this.question_id,
        uniqid: this.$store.getters.uniqid
      })
      this.currentQuestion = data.question || {}
      this.currentQuestion.question_id = data.question_id
      console.log(this.currentQuestion)
    }
  }
}
</script>
<style lang="scss" scoped>
.page-question-detail {
  width: $contentWidth;
  max-width: 100%;
  margin: 20px auto 74px;
}
</style>